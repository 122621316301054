@import "../variables/variables";

.table{
	border: 1px solid #ccc;
	border-bottom: 0px;
	border-collapse: inherit;
	@media (max-width: 1200px){
		border: 0px;
	}
	&>thead{
		&>tr{
			&>th{
				padding: 10px 19px;
				font-size: 15px;
				border-bottom: 0px;
				border-bottom: 1px solid #ccc;
			}
		}
	}
	&>tbody{
		&>tr{
			&>td{
				border-top: 0px;
				cursor: default;
				border-bottom: 1px solid #ccc;
			}
			&.no-result{
				td{
					cursor: default;
					text-transform: uppercase;
				}
			}
		}
	}
}



.table-main{
	table{
		width: 100%;
		margin-bottom: 0;
	}
	thead{
		background: #fafafa;
		border-radius: 3px 3px 0 0;
		border: 0;
		border-bottom: 1px solid #ccc;

		tr {
			th{
				color: #565656;
				vertical-align: middle;
				white-space: normal;
				word-wrap: break-word;
				padding: 10px 19px;
				@media (max-width: 1200px){
					white-space: nowrap;
				}
				a{
					&.sort{
						color: #1570a6;
						text-decoration: none;
						font-size: 15px;
					}
					&.sort-up{
						padding-right: 15px;
						background: url(#{$base_url}/images/icons/icon-sort-up2.svg) no-repeat center right;
						background-size: 10px 10px;
						&:hover{
							text-decoration: none;
						}
					}
					&.sort-down{
						padding-right: 15px;
						background: url(#{$base_url}/images/icons/icon-sort-down2.svg) no-repeat center right;
						background-size: 10px 10px;
						&:hover{
							text-decoration: none;
						}
					}
				}
			}
		}
	}
	tbody{
		tr{
			border-bottom: 1px solid #ccc;
			&:last-child{
				border-bottom: 0;
			}
			&:hover{
				background: #f6f6f6;
				@media (max-width: 800px){
					background: transparent;
				}
			}
			&:after{
				content: '';
				display: block;
				visibility: hidden;
				clear: both;
			}
			td{
				color: #565656;
				vertical-align: middle;
				white-space: normal;
				word-wrap: break-word;
				padding: 10px 19px;
				font-size: 13px;
				cursor: pointer;
				a{
					color: #565656;
					text-transform: none;
					text-decoration: none;
					@media (max-width: 800px){
						white-space: normal;
					}
					&.btn-main{
						color: #ffffff;
					}
					&:hover{
                        color:$blue;
						text-decoration:none;
					}
				}
			}
		}
		.load-more-rows{
			td{
				padding: 20px 0  18px 0;
				.btn-main{
					padding: 0 15px;
				}
			}
			&:hover{
				background: none;
			}
		}
		.no-result{
			td{
				height: 77px;
				padding: 32px 19px;
			}
			&:hover{
				background: none;
			}
		}
	}
}

.customers-tbody{
    overflow-y: auto;
    max-height: 571px;
}
/* ---- /css for table ---- */

.table-responsive {
	@media (max-width: 1400px){
	    width: 100%;
	    margin-bottom: 15px;
	    overflow-y: hidden;
	    -ms-overflow-style: -ms-autohiding-scrollbar;
		    .table{
			tbody{
				tr{
					td{
						white-space: nowrap;
					}
					&.no-result{
						td{
							/*border-bottom: 0px;*/
						}
					}
				}
			}
		}
	}
	@media (max-width: 1200px){
		border: 1px solid #ddd;
		border-bottom: 0;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fafafa+0,ffffff+46 */
		background: #fafafa; /* Old browsers */
		background: -moz-linear-gradient(top, #fafafa 0%, #ffffff 46%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, #fafafa 0%,#ffffff 46%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, #fafafa 0%,#ffffff 46%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
	}
}


.tracking-code{
	color:$blue !important;
}

.btn-reconfigure-device, .tracking-code{
	color:$blue !important;
}

.btn-reconfigure-device{
	line-height: 28px;
}