/* ---- breadcrumb ----*/
.breadcrumb{
    padding: 0;
    background: none;
    @media (max-width: 850px){
    	display: none;
    }
    ul{
    	li{
			color: #565656;
			text-decoration: none;
			background: #efeded;
			position: relative;
			display: inline-block;
            font-size: 13px;
			min-width: 50px;
			vertical-align: middle;
			&:first-child{
				border-radius: 5px 0 0 5px;
				a{
					padding: 8px 8px 8px 20px;
				}
			}
			&:hover{
				background: #d6d4d4;
				&:after{
					border-left: 10px solid #d6d4d4;
				}
			}
			&:after{
				content: "";
				display: block;
				width: 0;
				height: 0;
				border-top: 18px solid transparent; /* Go big on the size, and let overflow hide */
				border-bottom: 18px solid transparent;
				border-left: 10px solid #efeded;
				position: absolute;
				top: 50%;
				margin-top: -18px;
				left: 100%;
				z-index: 2;
			}
			&:before{
 				content: "";
				display: block;
				width: 0;
				height: 0;
				border-top: 18px solid transparent;
				border-bottom: 18px solid transparent;
				border-left: 10px solid #ffffff;
				position: absolute;
				top: 50%;
				margin-top: -18px;
				margin-left: 1px;
				left: 100%;
				z-index: 1;
			}
    		a{
    			display: block;
    			padding: 8px 10px 8px 20px;
    			text-decoration: none;
    			&:hover{
    				text-decoration: none;
    				color: #23527c;
    			}
    			&:focus, &:active{
    				text-decoration: none;
    			}

				.fa{
					font-size:16px;
				}
    		}
    		img{
    			margin-top: -3px;
    		}

    		&:last-child:hover{
    			background: #efeded;
    		}
    		&:last-child:hover:after {
		    border-left: 10px solid #efeded;
		}
    	}
    	li:last-child{
    		padding: 8px 10px 8px 20px;
    	}
    }
}
/* ---- /breadcrumb ----*/
