#footer-terms-link{
  text-align: right;
  padding: 15px;
}

#js-resize-placeholder {
  height: 0;
  width: 0;
  display: block;
}

@media (max-width: 800px) {
  #js-resize-placeholder {
    display: none;
  }
}