@import "../variables/variables";
/* ---- css for form elements ---- */
/**
DON'T OVERRIDE FORM GROUP CLASS - DEFAULT BOOTSTRAP ADD YOUR CUSTOM CLASS
 */
 .has-error .form-control{
    box-shadow: none;
 }
.form-group{
        &.has-error{
            input{
                &[type="text"], &[type="password"], &[type="email"]{
                    border-bottom:1px solid $red;
                  color:darken($red, 10%);
                    box-shadow:none;
                }
            }

        }
        font-family: $main_font;
        input{
            &::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &:-moz-placeholder{
                color: #565656;
            }
        &[type="text"], &[type="password"], &[type="email"]{
            font-family: $main_font;
            display: inline-block;
            max-width: 320px;
            height: 30px;
            padding: 0;
            background: #fff;
            border: 0;
            border-bottom: 1px solid #9a9a9a;
            color: #565656;
            font-size: 13px;
            border-radius: 0px;
            box-shadow: none;
            &:disabled{
                background: none!important;
                border-bottom: 1px solid #bfbfbf;
                color: #bfbfbf;
            }
            &:required{

            }
            &::-ms-clear{
                display: none;
            }
        }
        input{
            &[type="file"]{
                width: 230px;
                color: #565656;
                font-size: 13px;
            }
        }        
    }
    textarea{
         font-family: $main_font;
         display: inline-block;
         width: 320px;
         height: 23px;
         padding: 0;
         background: #fff;
         border: 0;
         border-bottom: 1px solid #9a9a9a;
         color: #565656;
         font-size: 13px;
         border-radius: 0px;
         box-shadow: none;
         resize: vertical;
         &:disabled{
             background: none!important;
             border-bottom: 1px solid #bfbfbf;
             color: #bfbfbf;
         }
    }
    select{
        font-family: $main_font;
        display: inline-block;
        height: 30px;
        max-width: 320px;
        background: #fff url('#{$base_url}/images/icons/select-arrow.png') no-repeat;
        background-position: right center;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        border: 0px;
        border-bottom: 1px solid #9a9a9a;
        border-radius: 0px;
        box-shadow: none;
        font-size: 13px;
        padding: 0;
        &::-ms-expand{
            display: none;
        }
        &:disabled{
            background: transparent url("/bundles/app/images/icons/select-arrow-disabled.png") no-repeat;
            background-position: right center;
            border-bottom: 1px solid #bfbfbf;
            color: #bfbfbf;
            cursor: not-allowed;
        }
        &:focus {
           border-bottom: 1px solid #9a9a9a;
           box-shadow: none;
        }
    }
    label{
        text-align: right;
        width: 150px;
        &:after{
            content:":";
        }
        &.buton-label{
            &:after{
                content: "";
            }
        }
        &.checkbox-custom-label{
            width: auto;
            margin-bottom: -3px;
            &:after{
                content:"";
            }
        }
    }

}

.form-group-radio, .form-group-checkbox{
    line-height: normal;
    label{
        font-family: $main_font;

        input{
            display: none;
        }
        span{
            height: 20px;
            width: 20px;
            display: inline-block;
            border: 1px solid #565656;
            border-radius: 4px;
            cursor: pointer;
            vertical-align: middle;
            border-radius: 50%;
        }
        p{
            display: inline-block;
            vertical-align: middle;
            margin-left: 6px;
            color: #333333;
            font-size: 13px;
            cursor: pointer;
            font-weight: 300;
        }
    }
}

.form-group-radio{
    .checkbox-custom-label{
        input{
            &[type=radio]{
                &:checked{
                    &+span{
                        background: #ffffff;
                        border: 4px solid #0093D5;
                    }
                    &:disabled{
                        &+span{
                            background: #ffffff;
                            border: 4px solid #73C4E8;
                            cursor: not-allowed;
                        }
                    }
                }
                &:disabled{
                    &+span{
                        border: 1px solid rgba(86,86,86,0.3);
                        cursor: not-allowed;
                    }
                    &+p{
                        color: rgba(86,86,86,0.8);
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
}

.form-group-checkbox{
    label{
        span{
            border-radius: 3px;
        }
     }
}

.form-group-checkbox{
    .checkbox-custom-label{
        input{
            &[type=checkbox]{
                &:checked{
                    &+span{
                        background: #0093D5 url(#{$base_url}/images/icons/apply-white.svg) no-repeat center;
                        background-size: 14px 14px;
                        border: 1px solid #0093D5;
                    }
                    &:disabled{
                        &+span{
                            background: rgba(0, 147, 213, 0.4) url(#{$base_url}/images/icons/apply-white.svg) no-repeat center center;
                            background-size: 14px 14px;
                            border: 1px solid rgba(86,86,86,0.3);
                            cursor: not-allowed;
                            &+p{
                                opacity: 0.3;
                            }
                        }
                        &+p{
                            color: rgba(86,86,86,0.8);
                            cursor: not-allowed;
                        }
                    }
                }
                &:disabled{

                    &+span{
                        border: 1px solid rgba(86,86,86,0.3);
                        background-color: #EFEFEF;
                        cursor: not-allowed;

                        &+p{
                            opacity: 0.3;
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }
    }
}
.help-block{
    width: 312px;
    position: absolute;
    left: 360px;
    top: -7px;
    padding: 4px 0 3px 25px;
    border-radius: 3px;
    border: 1px solid #ebafa6;
    background: #f5dbd9;
    color: #565656;
    font-size: 12px;
    font-style: normal;

    &:before{
        width: 26px;
        height: 16px;
        position: absolute;
        margin-left: -20px;
        content: "fff";
        background: url('#{$base_url}/images/icons/form-error.svg') no-repeat left center;
        background-size: 15px 15px;
        text-indent: -9999px;
    }

    ul{
        float: left;
        margin: 0!important;
        list-style: none;
        width:90%;
    }
}
.required:before{
    content: "* ";
    color: #b20303;
}

.not-required .required:before{
    content: " ";
}

/* ---- filters ---- */
.filters{
    margin-bottom: 16px;
	.form-group{
		display: inline-block;
		margin-bottom: 0;
		vertical-align: middle;
	}
	input{
		&[type="text"]{
			width: 200px;
			margin-right: 5px;
		}
		&[type="submit"], button{
			margin-left: 15px;
			line-height: 24px;
		}
	}
	.form-group-checkbox{
		margin: 0px 0 0 15px;
		display: inline-block;
		vertical-align: middle;
	}
	.ofline-devices{
		margin-top: 3px;
		color: #1570a6;
		font-size: 13px;
		text-decoration: underline;
		&:hover{
			text-decoration: none;
		}
	}
}

/* ---- /filters ---- */


@keyframes ripple {
    0% {
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
    }
    50% {
        box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
    }
    100% {
        box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
    }
}

.radio {
    margin: 16px 0;
    display: inline-block;
    input[type="radio"] {
        display: none;
        &:checked + label:before {
            border-color: $md-radio-checked-color;
            animation: ripple 0.2s linear forwards;
        }
        &:checked + label:after {
            transform: scale(1);
        }
    }
    label {
        display: inline-block;
        height: $md-radio-size;
        position: relative;
        padding-left: ($md-radio-size + 10px);
        margin: 0 0 0 25px;
        cursor: pointer;
        vertical-align: bottom;
        width: auto;
        &:before, &:after {
            position: absolute;
            content: '';
            border-radius: 50%;
            transition: all .3s ease;
            transition-property: transform, border-color;
        }
        &:before {
            left: 0;
            top: 0;
            width: $md-radio-size;
            height: $md-radio-size;
            border: 1px solid $md-radio-border-color;
        }
        &:after {
            top: $md-radio-size / 2 - $md-radio-checked-size / 2;
            left: $md-radio-size / 2 - $md-radio-checked-size / 2;
            width:$md-radio-checked-size;
            height:$md-radio-checked-size;
            transform: scale(0);
            background:$md-radio-checked-color;
        }
    }

    &:first-child {
        label {
            margin-left: 0; //fix aligment of the first element
        }
    }
}


$md-checkbox-margin: 16px 0;
$md-checkbox-checked-color: #0093D5;
$md-checkbox-border-color: rgba(0, 0, 0, 0.54);
$md-checkbox-border-color-disabled: rgba(0, 0, 0, 0.26);
$md-checkbox-checked-color-disabled: rgba(0, 0, 0, 0.26);

$md-checkbox-size: 18px;
$md-checkbox-padding: 4px;

$md-checkmark-width: 2px;
$md-checkmark-color: #fff;
.form-group{
    .checkbox{
        label{
            width:auto;
        }
    }
}
.checkbox {
    position: relative;
    margin: $md-checkbox-margin;
    text-align: left;

    &.md-checkbox-inline {
        display: inline-block;
    }

    label {
        cursor: pointer;
        line-height: .5em;
        padding-left: 25px;
        display: inline-table;
        //vertical-align: middle;
        &:before, &:after {
            content: "";
            position: absolute;
            left:0;
            top: 0.5em;
        }

        &:before {
            // box
            width: $md-checkbox-size;
            height: $md-checkbox-size;
            background: #fff;
            border: 1px solid $md-checkbox-border-color;
            cursor: pointer;
            transition: background .3s;
            border-radius: 3px;
        }

        &:after {
            // checkmark
            top: 8px;
            left: 2px;
        }
    }

    input[type="checkbox"] {
        outline: 0;
        margin-right: $md-checkbox-size - 10px;
        visibility: hidden;

        &:checked {
            + label:before{
                background: $md-checkbox-checked-color;
                border:none;
            }
            + label:after {
                background: url(#{$base_url}/images/icons/apply-white.svg) no-repeat center;
                background-size: 14px 14px;
                width: 14px;
                height: 14px;
            }
        }

        &:disabled {
            +label{
                cursor:not-allowed;
            }
            + label:before{
                border-color: $md-checkbox-border-color-disabled;
                cursor:not-allowed;
            }
            &:checked {
                + label:before{
                    background: $md-checkbox-checked-color-disabled;
                }
                + label{
                    cursor: not-allowed;
                }
            }
        }
    }
}

.form-horizontal{
    .checkbox{
        width: auto;
        display: inline;
        margin-right: 20px;
    }
    .label-phonenumber{
        display: inline-block;
        vertical-align: bottom;
        float: none;
    }
    .phonenumber-container{
        display: inline-block;
        vertical-align: bottom;
        float: none;
    }
}


