body{
    font-family: 'Muli', sans-serif;
}
.site-width{
    min-height: calc(100vh - 111px);
    @extend .container-fluid
    //max-width: 1920px;
    //min-width: 1020px;
    //margin: 0 auto;
    //@media screen and (max-width: 1020px){
    //    min-width: auto;
    //}
}
ul, p, h1, h2,h3,h4,h5{
    margin: 0;
    padding: 0;
}
input, textarea, select, button{
    outline: 0
}
a{
    text-decoration: none;
}

.block{
    width: 100%;
    float: left;
    &.loadmore_btn_wrapp{
        margin-top: 47px;
    }
}
.clr{
    clear: both;
}
.block-distance{
    margin-top: 27px;
    padding:  0 34px 40px 34px;
    @media screen and (max-width: 768px){
        padding: 0;
    }
}
.error-msg{
    width: 312px;
    position: absolute;
    bottom: -25px;
    left: 230px;
    padding: 2px 20px;
    font-size: 11px;
    background: #002538 url(#{$base_url}/images/icons/error-arrow.png) no-repeat 5px center;
    color: #fff;
    display: none;
}
.error-msg-red{
    color: red;
}
/* ---- badges ---- */
.new-badge{
    width: 28px;
    height: 15px;
    background: #efd603;
    border-radius: 8px;
    color: #000;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
}
.active-badge{
    min-width: 65px;
    height: 21px;
    display: inline-block;
    margin-bottom: 0;
    background: #dff0d0;
    border: 1px solid #65b500;
    border-radius: 11px;
    color: #266900;
    font-size: 11px;
    line-height: 21px;
}
.nonactive-badge{
    min-width: 65px;
    height: 21px;
    display: inline-block;
    margin-bottom: 0;
    background: #f5dbd9;
    border: 1px solid #ebafa6;
    border-radius: 11px;
    color: #a32100;
    font-size: 11px;
    line-height: 21px;
}
.criterium-badge{
    width: 49px;
    height: 21px;
    display: inline-block;
    margin-bottom: 0;
    background-color: #f5dbd9;
    border: 1px solid #ebafa6;
    border-radius: 11px;
    color: #a32100;
    font-size: 11px;
    line-height: 21px;
}
/* ---- /badges ---- */

/* ---- table-nav ---- */
.tabs-wrapper{
	border-bottom: 1px solid #ddd;
	li{
	display: inline;
	float: left;
	padding: 0 34px;
    @media screen and (max-width: 1124px){
        padding: 0 20px;
    }
		a{
			float: left;
			padding: 13px 2px;
			color: #747474;
			font-size: 14px;
			font-weight: 400;
			&:focus,
			&:hover{
				text-decoration: none;
				border-bottom: 3px solid #007cbb;
			}
		}
        &:first-child{
            @media screen and (max-width: 1124px){
                padding-left: 34px;
            }
        }
	}
	li.active a{
		border-bottom: 3px solid #007cbb;
	}
}
/* ---- /table-nav ---- */

/* ---- table navigation ---- */
.table-navigation{
		ul{
			border-bottom: 1px solid #ccc;
			li{
				display: inline;
				a{
					float: left;
					margin-right: 45px;
					color: #747474;
					font-size: 14px;
					padding: 8px 0;
                    &:hover{
                        text-decoration: none;
                        border-bottom: 3px solid #007cbb;
                        color: #000;
                    }
				}
                &.active{
                    border-bottom: 3px solid #007cbb;
                    a{
                        border-bottom: 3px solid #007cbb;
                        color: #000;
                    }
                }
			}
	}
}

/* ---- /table navigation ---- */

.btn-main{
    background: #007cbb;
    border-radius: 3px;
    border: 1px solid #007cbb;
    padding: 0 15px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out;
    vertical-align: top;
}
.btn-big{
    width: 312px;
    height: 34px;
    line-height: 34px;
}
.btn-main{
    &:disabled{
        background: rgba(0, 124, 187, 0.5);
        cursor: default;
        &:hover{
            background: rgba(0, 124, 187, 0.5);
        }
    }
    &:hover{
        background: #095880;
        color: #fff;
        text-decoration: none;
    }
}

.btn-main-invert{
    width: auto;
    height: 24px;
    padding: 0 10px;
    background: none;
    border-radius: 3px;
    border: 1px solid #007cbb;
    color: #007cbb;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.btn-main-invert{
    &:disabled{
        border: 1px solid rgba(0, 124, 187, 0.5);
        color: rgba(0, 124, 187, 0.5);
        cursor: default;
        &:hover{
            border: 1px solid rgba(0, 124, 187, 0.5) !important;
            color: rgba(0, 124, 187, 0.5) !important;
            cursor: default !important;
        }
    }
    &:hover{
        border: 1px solid #0c4867;
        color: #0c4867
    }
}

.display_none{
    display: none;
}
.customer_deatiles_edit{
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid lightgray;
    color: gray;
    font-size: 13px;
    width: 100%;
}
/* ---- /css for form elements ---- */

/* ---- css for calendar ---- */
.ui-widget{
    &.ui-widget-content{
        border: 1px solid #264656;
        border-radius: 0;
        font-family: "Muli";
    }
}
.ui-widget-header{
    border: 0;
    background: #264656;
    border-radius: 0;
    color: #fff;
}
.ui-datepicker{
    .ui-datepicker-title{
        font-size: 16px;
    }
}
.ui-datepicker{
    th{
        color: #264656;
    }
}
.ui-state-default{
    padding: 4px 0;
    border: 0;
    border-bottom: 1px solid #9a9a9a;
    background: #fff;
    color: #565656;
    text-align: center;
}
.ui-widget-content{
    .ui-state-default{
        @extend .ui-state-default;
    }

}
.ui-datepicker-today{
    .ui-state-default{
        border: 0;
        border-bottom: 1px solid #264656;
        background: #264656;
        color: #fff;
    }
}
.ui-datepicker-current-day{
    .ui-state-default{
        border: 0;
        border-bottom: 1px solid #4d7a90;
        background: #4d7a90;
        color: #fff;
    }
}

.ui-datepicker{
    .ui-datepicker-prev{
        width: 20px;
        height: 25px;
        top: 5px;
        cursor: pointer;
        -webkit-transition: opacity .3s ease-in-out;
        transition: opacity .3s ease-in-out;
        .ui-icon{
            background:  url(#{$base_url}/images/icons/calendar-prev.png) no-repeat center;
        }
        &:hover{
            background: none;
            border: 0;
            opacity: 0.7;
            left: 2px;
        }
    }
    .ui-datepicker-next{
        @extend .ui-datepicker-prev;
        .ui-icon{
            background:  url(#{$base_url}/images/icons/calendar-next.png) no-repeat center;
        }
        &:hover{
            background: none;
            border: 0;
            opacity: 0.7;
            right: 2px;
        }
    }
}
/* ---- /css for calendar ---- */



/* ---- asf ---- */
.asf-main-title{
    margin-bottom: 16px;
    color: #000;
    font-size: 22px;
    line-height: 36px;
}

.asf-subtitle{
    font-size: 18px;
}
.asf-subtitle{
    span{
        font-size: 12px;
    }
}
.asf-devices{
    width: 60%;
}

.asf-account{
    h2{
        .asf-main-title{
            margin: -2px 0 0 12px;
        }
    }
    margin-top: 24px;
    margin-top: 0;
}
.dealer-data{
    h2{
        .asf-main-title{
            margin: -2px 0 0 12px;
        }
    }
}

.dealer-data h2 img,
.asf-account h2 img{
    width: 25px
}
.dealer-data h2 a,
.asf-account h2 a{
    margin-left: 15px;
    color: #1570a6;
    font-size: 14px;
    line-height: 36px;
    text-decoration: underline;
}
.dealer-data h2 a:hover,
.asf-account h2 a:hover{
    text-decoration: none;
}
.asf-account > p{
    color: #565656;
    font-size: 13px;
    font-weight: 400
}
.asf-account ul{
    margin-top: 20px;
}
.asf-account ul li{
    display: block;
    width: 100%;
    float: left;
    clear: both;
}
.asf-account ul li label{
    width: 200px;
    float: left;
    margin: 0;
    color: #565656;
    font-size: 13px;
    font-weight: 700;
    line-height: 24px;
}
.asf-account ul li p{
    width: calc(100% - 210px);
    float: left;
    margin-left: 10px;
    color: #565656;
    font-size: 13px;
    line-height: 24px;
}
.border-bottom-error{

    border-bottom: 1px solid #ebafa6!important;
}
label sup{
    color: red;
}
/* ---- /asf ---- */

/* ---- customer ---- */

/* ---- /customer ---- */

/* ---- account details ---- */
.account-column{
    /*width: 570px;*/
    float: left;
}
.account form{
    width: 100%;
    float: left;
    position: relative;
    /*   background: rgba(255,255,255,0.8);*/
}
.account .form-group,
.account .form-group-inner{
    position: relative;
}
.account .form-group-label{
    width: 200px;
    margin: 0 30px 0 0;
}
.account .form-group-password{
    margin: 40px 0;
    padding: 30px 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
.account .btn-main{
    margin-left: 230px;
    padding: 0 15px;
}
.account .form-group-password span{
    margin-top: -10px
}
.account .form-group-password .clr{
    height: 23px
}
.account select{
    width: 320px;
    height: 23px;
    background-color: transparent;
}
.account input[type="text"],
.account input[type="password"]{
    background-color: transparent;
}
.account .upload-image{
    width: 312px;
    height: 312px;
    position: relative;
    margin: 0 0 0 230px;
    border: 1px solid #ccc;
}
.account .upload-image img{
    max-width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
}
.account-column .form-group-checkbox input[type="text"]{
    margin: -5px 0 0 15px;
}
.account .form-group ul{
    margin-left: 230px
}
.account .form-group ul li{
    width: auto;
    clear: none;
    display: inline;
}
.account .form-group ul li a{
    color: #212529
}
.account .form-group ul li:nth-child(2){
    margin: 0 10px
}
#change-password-button{
    width: 150px;
    margin-left: 230px
}
/* ---- /account details ---- */
/* ---- loader ----*/
.loader {
    border: 5px solid #d9d9d9;
    border-radius: 50%;
    border-top: 5px solid #007cbb;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
.loader-small{
    width: 18px;
    height: 18px;
    border-width: 2px
}
.loader-meddium{
    width: 36px;
    height: 36px;
    border-width: 3px;
}
.loader-large{
    width: 72px;
    height: 72px;
}
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
/* ---- loader ----*/
/* ---- popup ----*/
.readonly{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    display: none;
}
.overlay{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    display: none;
    background: rgba(0,0,0,0.5);
}

.overlay-popup {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: none;
    background: transparent;
}

.overlay-flash{
    display:block;
}
.overlay-loader{
    width: 100%;
    height: 100%;
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    z-index: 20;
    background: rgba(0,0,0,0.5) url(../images/loader.gif) no-repeat center;
}
.popup-wrapp{
    width: 624px;
    position: fixed;
    top: 20%;
    left: 50%;
    z-index: 100;
    display: none;
    margin-left: -312px;
    border: 2px solid #505050;
    @media screen and (max-width: 650px){
        width: auto;
        left: 10px;
        right: 10px;
        margin-left: 0;
    }
}
.popup{
    /*padding: 24px;*/
    background: #fff;
    border-radius: 3px;
    -webkit-box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.07);
            box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.07);
}
.popup-head{
    padding: 10px 24px;
    background: #002538;
}
.popup-head p{
    width: calc(100% - 20px);
    margin-left: -2px;
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
}
.popup-head .popup-close{
    width: 16px;
    height: 16px;
    margin-top: 2px;
    background: url(#{$base_url}/images/icons/close-light.svg) no-repeat center;
    background-size: 30px 30px; 
    cursor: pointer;
}
.popup-body{
    padding: 24px;
    .button-holder{
        text-align: center;
        display: block;
        clear: both;        
    }
    .form-group{
        margin-bottom: 15px !important;
    }
}
.popup-body .popup-message{
    margin-bottom: 24px;
    color: #565656;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}
.popup-btn button{
    min-width: 78px;
    height: 36px;
    margin-left: 11px;
}
.offline-device-popup{
    top: 10%
}
.add-dealer-popup{
    top: 10%
}
#addDealerForm label{
    width: 25%;
}
#addDealerForm input, #addDealerForm select{
    width:65%;
}
.popup-wrapp#manufacturer-popup{
    width: 1020px;
    top: 10%;
    margin-left: -510px
}
.popup-part{
    border-bottom: 1px solid #ccc
}
.popup-part.last{
    margin-bottom: 23px
}
.popup-part-checkboxes{
    width: 50%;
    height: 250px;
    overflow-y: auto;
    padding-right: 2%;
}
.popup-part-checkboxes .no-result{
    font-size: 13px
}
.popup-part-checkboxes input[type="text"]{
    width: calc(100% - 31px);
    margin: -5px 0 0 15px
}
.popup-part-checkboxes .form-group{
    margin-bottom: 8px
}
.popup-manufacturer-form{
    width: 50%;
    padding-left: 2%;
    border-left: 1px solid #ccc
}
.popup-manufacturer-form .form-group-radio{
    margin-bottom: 10px
}
.popup-manufacturer-form .form-group select{
    width: calc(100% - 115px);
    margin: -5px 0 0 10px
}
.popup-manufacturer-form .form-group input[type="text"]{
    width: calc(100% - 70px);
    margin: -5px 0 0 10px
}
.popup-manufacturer-form .form-group.last{
    margin-bottom: 0
}
.popup-part-assign{
    margin-top: 15px
}
.popup-part-assign input[type="button"]{
    width: 140px
}
.popup-part-assign input[type="text"]{
    width: calc(100% - 155px);
    margin: 5px 0 0 15px;
}
.popup-btn-text{
    width: 65%;
    color: red;
    font-size: 13px;
}
.subtitle-add-dealer{
    margin-bottom: 20px;
    font-size: 17px;
    border-bottom: 1px solid gray;
}
#loader-popup{
    width: 200px;
    margin-left: -100px;
}
#loader-popup img{
    width: 60px
}
#loader-popup .popup-head p{
    width: 100%
}
.popup .cartridges-block input[type="text"]{
    width: 84%;
}

.flash-popup{
    display:block;
    background:#fff;
}
/* ---- /popup ----*/
/* ---- multiselect ----*/
.multiselect {
    width: 240px;
    position: relative;
    margin-top: -7px;
}
#checkboxes-wrapper {
    display: none;
    background: #fff;
    border: 1px solid #9a9a9a;
    position: absolute;
    z-index: 2;
    top: 35px;
    left: 0;
    padding: 10px;
    width: 100%;
}
#checkboxes-wrapper label {
    display: block;
    margin-top: 10px
}
#checkbox-overlay{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: none;
}
/* ---- /multiselect ----*/
/* ---- range ----*/
input[type=range][orient=vertical]
{
    -webkit-writing-mode: bt-lr;
        -ms-writing-mode: bt-lr;
            writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* WebKit */
    width: 8px;
    height: 315px;
    padding: 0 5px;
    position: absolute;
    top:-6px;
    right: -60px;
    opacity: 0;
}
.range-value{
    position: relative;
}
/* ---- /range ----*/
/* ---- tooltip ----*/
.tooltip-wrapp{
    /*width: 130px;*/
    position: absolute;
    opacity: 0.9;
    bottom: 24px;
    padding: 2px 10px;
    background: #fff;
    border: 1px solid #747474;
    border-bottom: 2px solid #747474;
    border-radius: 4px;
    display: none;
    box-shadow: 0 8px 6px -6px rgba(0, 37, 56, 0.6);
    text-align: center;
    white-space: nowrap;
    cursor: auto !important;
    z-index: 100;
}
.tooltip-wrapp p{
    font-size: 12px;
    color: #1570a6;
}
.tooltip-wrapp img{
    position: absolute;
    bottom: -6px;
}
.tooltip-wrapp.left{
    right: 0;
    text-align: right;
}
.tooltip-wrapp.left img{
    right: 6px;
}
.tooltip-wrapp.right{
    left: 0;
    text-align: left;
}
.tooltip-wrapp.right img{
    left: 6px;
}
.relative{
    position: relative;
}
.city-state-zip-li{
    height: 80px;
}
/* ---- /tooltip ----*/
/* ---- choosen ----*/
#search-part-box,
#search-printer-box{
    margin-top: 30px;
    margin-bottom: -20px
}
.chosen-container{
    max-width: 320px !important;
}
.chosen-container-single .chosen-single{
    padding: 0;
    background: #fff;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #9a9a9a;
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #565656;
    font-size: 13px;
}
.chosen-container-active.chosen-with-drop .chosen-single{
    background: #fff;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #9a9a9a;
}
.chosen-container-single .chosen-drop{
    margin-top: 0;
    border-radius: 0;
}
.chosen-container-single .chosen-search{
    padding: 0 10px;
}
.chosen-container-single .chosen-search input[type="text"]{
    width: 100%;
    margin: 5px 0 0 0;
    background: #fff url(#{$base_url}/images/icons/chosen-sprite.png) no-repeat 100% -20px
}
.chosen-container-single .chosen-single div b {
    display: block;
    width: 100%;
    height: 100%;
    background: url(#{$base_url}/images/icons/select-arrow.png) no-repeat center right ;
}
.chosen-container-active.chosen-with-drop .chosen-single div b{
    background: url(#{$base_url}/images/icons/select-arrow-up.png) no-repeat center right ;
}
.chosen-container .chosen-results{
    max-height: 145px;
    overflow-y: auto;
    margin: 0 0 5px 0;
    padding: 0 10px;
    background: #fff;
}
.chosen-container .chosen-results li{
    padding: 5px 0;
    word-wrap: break-word;
}
.chosen-container .chosen-results li:hover{
    color: #007cbb
}
.chosen-container .chosen-results li.no-results{
    margin-top: 5px;
    background: none
}
.chosen-container .chosen-results li.no-results:hover{
    color: #212529
}
.chosen-container .chosen-results li.highlighted {
    background:#fff;
    color:#007cbb
}
.selectBox{
    position: relative;
}
.data-loader-manufacturer{
    position: absolute;
    top: -5px;
    right: -5px;
    background: #fff;
}
.data-loader-manufacturer img{
    width: 20px
}
/* ---- /choosen ----*/

/* ---- toggle button ----*/
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 19px;
}

.switch input {display:none;}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #002538;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 3px;
  border-radius: 50%;
  background: #fff url(#{$base_url}/images/icons/toggle-left.png) no-repeat 3px center;
  background-size: 5px;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: #002538;
}
input:checked + .slider:before{
    background: #fff url(#{$base_url}/images/icons/toggle-right.png) no-repeat  6px center;
    background-size: 5px;
}
input:focus + .slider {
  -webkit-box-shadow: 0 0 1px #2196F3;
          box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {

}

/* ---- general rules ----*/
.last-updated {
    float: right;
    margin-top: 11px;
}

.last-updated {
   p {
      padding-left: 26px;
      background: url(#{$base_url}/images/icons/last-updated.svg) no-repeat left;    
      background-size: 18px 18px;  
      color: #565656;
      font-size: 13px;
   }
   &.last-updated-error {
      p {
        background: url(#{$base_url}/images/icons/last-reported.svg) no-repeat left;
        background-size: 18px 18px; 
    }
   }
}

.float-left{
    float: left;
}

.float-right{
    float: right;
}
.visibility-off{
    visibility: hidden;
}

.no-padding{
    padding:0;
}
.no-margin{
    margin:0;
}
/* ---- /range ----*/

.customer-page, .dealer-data, .account{
    .form-group{
        label{
            margin-right:10px;
        }
    }
}

.btn-danger-delete {
    display: inline-block;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    color: #cb2431;
    background-color: #fafbfc;
    background-image: linear-gradient(-180deg,#fafbfc,#eff3f6 90%);
    user-select: none;
    background-repeat: repeat-x;
    background-position: -1px -1px;
    background-size: 110% 110%;
    border: 1px solid rgba(27,31,35,.2);
    border-radius: .25em;
    -webkit-appearance: none;
}
.btn-danger-delete:focus {
    box-shadow: 0 0 0 0.2em rgba(203,36,49,.4);
}
.btn-danger-delete:hover {
    color: #fff;
    background-color: #cb2431;
    background-image: linear-gradient(-180deg,#de4450,#cb2431 90%);
    border-color: rgba(27,31,35,.5);
}
.btn-danger-delete.selected, .btn-danger-delete:active, .btn-danger-delete[aria-selected=true], [open]>.btn-danger-delete {
    color: #fff;
    background-color: #b5202c;
    background-image: none;
    border-color: rgba(27,31,35,.5);
    box-shadow: inset 0 0.15em 0.3em rgba(27,31,35,.15);
}