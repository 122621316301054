.log-out{
  margin: 0;
  padding: 0;
  @media screen and (max-width: $screen-sm){
    text-align: center;
    a{
      margin: 0 auto;
    }
  }
}
.navbar-main{
  .container-fluid{
    margin:0;
    padding:0;
    .navbar-header{
      margin:0;
      padding:0;
      .navbar-toggle{
        margin:10px 0 10px 0;
        background:transparent;
        border: 0px;
        .icon-bar{
          width: 30px;
          height: 5px;
          border-radius: 0px;
          background-color: #ffffff;
        }
        @media screen and (max-width: 1023px){
          float: left;
        }
      }
      @media screen and (max-width: 530px){
        width: 45px;
        display: inline-block;
        position: relative;
        z-index: 100;
      }
    }
    .navbar-left-part{
      display: inline-block !important;
      @media screen and (max-width: 1023px){
        display: block !important;
      }
    }
    .navbar-right-part{
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
      ul{
        &.navbar-nav{
          margin: 0px;
          li{
            display: inline-block;
            vertical-align: middle;
            &.user{
              @media (max-width: 767px){
                display: none;
              }
            }
          }
          @media (max-width: 400px){
              text-align: center;
          }
          @media screen and (max-width: 530px){
            display: flex;
            flex-wrap: wrap;
            justify-content: right;
            width: calc(100% - 40px);
            float: right !important;
            li{
              &.company-switch-wrapper{
                order: 2;
                padding: 0 0 5px 0;
                .dropdown-menu{
                  &.switch-user-company-ul{
                    top: 35px;
                    right: 5px;
                    left: auto;
                    position: absolute;
                    width: 310px;
                    li{
                      width: 100%;
                      a{
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      @media screen and (max-width: 530px){
        width: 100%;
      }
    }
    .navbar-brand{
      margin:0;
      img{
        &.logo{
          max-width: 150px;
          max-height: 60px;
        }
      }
    }
  }
  .navbar-collapse{
    margin:0;
  }

  .navbar-brand{
    padding: 0;
    margin: 0;
    .logo{
      height: auto;
      padding:10px;
      @media screen  and (max-width:$screen-xs){        
        padding: 10px;
      }
    }
  }

  .header-title{
    padding: 0 14px;
    display: inline-block;
    vertical-align: middle;
    @media screen and (max-width: 1550px){
      display: none;
    }
    @media screen and (max-width: $screen-sm){
      border: 0;
      margin:0;
      padding: 0 0 0 10px;
      text-align: center;
    }
    a{
      color:#fff;
      &:hover{
        text-decoration:none;
      }
    }
    font-size:16px;
    line-height: 60px;
    float: left;
  }
  .navbar-nav{
    li{
      border-left: 1px solid rgba(255,255,255,0.15);
      @media screen and (max-width: $screen-sm){
        border: none;
      }
      a, .header-text{
        padding: 20px 25px;
      }
      @media screen and (max-width: 1200px){ 
        a, .header-text{
          padding: 20px 16px;
          @media screen and (max-width: 1100px){ 
            padding: 20px 5px;
          }
        }
      }
      &:last-child{
        border-right: 1px solid rgba(255,255,255,0.15);
        @media screen and (max-width: 991px){
          border-right: 0px;
        }
      }
    }
    &.navbar-right-part{
      margin: 0px;
      li{
        a#error-notification-icon{
          background: url(#{$base_url}/images/icons/notifications-bell-button.svg) no-repeat center center;
          background-size: 22px 22px;
        }
        ul{
          &.notification-menu{
            width: 300px;
            li{
              height: auto;
              &:hover{
                background: #f5f5f5;
              }
              a{
                padding: 0;
                height: auto;
                text-align: left;
                width: calc(100% - 35px);
                display: inline-block;
                padding: 5px 0 5px 10px;
                vertical-align: middle;
                &:hover{
                  background: transparent;
                }
              }
              .error-notification-count-list{
                position: absolute;
                display: inline-block;
                color: #ff3f3f;
                border-radius: 25px;
                right: 10px;
                font-size: 12px;
                font-weight: 700;
                text-align: right;
                margin-top: 7px;                
              }     
            }
          }

          .notification-menu-inner-loader {
            position: relative;
            float: right;
            top: 0;
            right: 16px;
          }
        }
        .error-notification-count {
          color: #ffffff;
          font-weight: 700;
          position: absolute;
          left: 30px;
          background: #ff3f3f;
          padding: 1px 5px;
          border-radius: 25px;
          font-size: 11px;
          margin-top: -10px;
          border: 2px solid #fff;
          line-height: normal;
          @media (max-width: 1200px){
            left: 9px;
          }
          @media (max-width: 1023px){
            position: relative;
            margin: 0px auto;
            left: unset;
            top: -10px;
          }
        }
        
      }
    }

    li{
      color:#fff;
      a, .header-text{
        text-align: center;
        @media screen and (max-width: $screen-sm){
          border:none;
        }
        height: 60px;
        min-width:40px;

        &:hover .tooltip-wrapp{
          display: block;
          @media (max-width: 1023px){
            display: none;
          }
        }
        .tooltip-wrapp{
          bottom: -35px;          
          p{
            color: $dark-blue;
          }
          img{
            top: -6px;
          }
        }        
      }

      .header-text{
        display:block;
      }
    }

  }

  #bs-example-navbar-collapse-1{
    .navbar-nav{
      li{
        a{
          @media (max-width: 1023px){
              height: auto;
              min-height: 10px;
              padding: 10px 10px;
              font-weight: 700;
              text-align: left;
            }
        }
      }
    }    
  }

  .nav > li{
    @media (max-width: 470px){
      position:unset;
    }
  }

  #bs-example-navbar-collapse-2{
    ul{
      &.navbar-nav{
        li{
          &.error-notifications{            
            ul{
              &.dropdown-menu{
                left: auto;
                right: 0px;
                @media (max-width: 1023px){
                  border-radius: 0px 0px 4px 4px;
                  position: absolute;
                  border: 1px solid rgba(0,0,0,.15);
                  background-color: #ffffff;
                  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
                  box-shadow: 0 6px 12px rgba(0,0,0,.175);
                  li{
                    a{
                      color: #333;
                    }
                  }
                  @media (max-width: 400px){
                    left: 0px;
                    right: 0px;
                    border-right: 0px;
                    border-left: 0px;
                    max-width: 100%;
                    width: 100%;
                  }
                }
                li{
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

  .navbar-right{
    @media screen and (max-width: $screen-sm){
      margin: 0;
      padding:0;
    }
  }

  .language-picker-wrapper, .company-switch-wrapper {
    border: none;
    padding: 16px 0px 16px 0px;    
    .dropdown-toggle {
      padding-top: 3px;
      background-color: transparent;
      color: #fff;
      border: none;
      &:focus, &:hover {
        background-color: rgba(255, 255, 255, 0.01);
        color: #fff;
        transition: 300ms;
      }
      .comp_name{
        max-width: 120px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }
    
    .dropdown-menu {
      min-width: 85px;
      padding: 3px 0 6px;
      top: 42px;
      border-radius: 0px 0px 4px 4px;
      left: -2px;
      position: absolute;
      border: 1px solid rgba(0,0,0,.15);
      background-color: #ffffff;
      -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
      box-shadow: 0 6px 12px rgba(0,0,0,.175);
      @media screen and (max-width: 1023px){
        left: -2px;
        top: 45px;
      }
      li { 
        color: #333;  
        cursor: pointer;
        font-size: 13px;
        padding: 5px 11px;
        display: block !important;
        &:hover { 
          text-decoration: none;
          background: #002538;
          color: #ffffff;
        }
        img{
          width: 15px;
          height: 15px;
          display: inline-block;
          margin-right: 5px;
          vertical-align: middle;
          margin-top: -3px;
        }
      }

      .switch-user-company-li {
        padding: 0;
        display: block;
        &:focus, &:hover {
          background-color: rgba(255, 255, 255, 0.01);
          color: #fff;
          transition: 300ms;
        }

        a {
          height: auto;
          font-size: 14px;
          text-align: left;
          padding: 5px 15px 5px 10px;
          color: #333 !important;
        }
      }
    }

    .switch-user-company-ul {
      min-width: 101%;
      max-width: 310px;
      li{
        width: 100%;
        a{
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .search-wrapper{
    border-left: 1px solid rgba(255, 255, 255, 0.20);
    .search-form{
      padding: 8px 5px;
      max-width: 700px;
      background: #002538;
      position: absolute;
      left: auto;
      right: 0px;
      margin: 0 auto;
      width: 700px;
      transition: opacity 2s ease-out;
      z-index: 100;
      @media screen and (max-width: 991px){
        left: auto;
        right: 0px;
        width: 400px;
        @media screen and (max-width: 630px){
          left: auto;
          right: 0px;
          width: 300px;
          @media screen and (max-width: 530px){
            @media screen and (max-width: 470px){
              width: 100%;
              left: 0px;
              right: 0px;
            }
          }
        }
      }
      .input-group{
        margin-top:5px;
      }
      .search-input{
        border-radius: 0;
        background-color: transparent;
        color: #fafafa;
        font-size: 14px;
        border-right: 0px;
        padding: 0 5px;
        border: 1px solid rgba(255, 255, 255, 0.20);
        height: 32px;
      }
      .search-input:focus {
        box-shadow: none;
        border: 1px solid rgba(255, 255, 255, 0.20);
      }
      .btn{
        background-color:transparent;
        border-radius:0;
        border: 1px solid rgba(255, 255, 255, 0.20);
        height: 32px;

        .fa{
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
    .search-icon{
      background: url(#{$base_url}/images/icons/search-magnifier-symbol-of-interface2.svg) no-repeat center center;
      background-size: 22px;
    }
  }
  .options{
    .options-icon{
      background: url(#{$base_url}/images/icons/options-icon.svg) no-repeat center center;
      background-size: 24px;
      @media screen and (max-width: $navbar_collapse_point){
        margin:0 auto;
      }
    }
  }

  .search-wrapper{
    min-height: 60px;
    min-width: 60px;
  }

  .asf-switch-account{
    a{
      padding: 12px 5px !important;
    }
    .fa{
      font-size:36px;
      color: rgba(255, 255, 255, 0.6)
    }
  }

  .log-out{
    a{
      background: url("#{$base_url}/images/icons/logout-light.svg") no-repeat center center;
      background-size: 35px;
      border-right: 0!important;
    }
  }

}


@media (max-width: $screen-sm-max) {
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    display: block;
  }

  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-nav {
    float: none!important;
    margin: 7.5px -15px;
  }
  .navbar-nav>li {
    float: none;
  }
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-text {
    float: none;
    margin: 15px 0;
  }
  /* since 3.1.0 */
  .navbar-collapse.collapse.in {
    display: block!important;
  }
  .collapsing {
    overflow: hidden!important;
  }
}

.navbar{
  @media screen and (max-width: 530px){
    min-height: 100px;
  }
}

#company-picker{
  @media screen and (max-width: 470px){
    position: absolute;
    left: 0px;
    right: 0px;
    top: 60px;
    width: 100%;
    .dropdown-toggle{
      float: right;
    }
    .dropdown-menu{
      &.switch-user-company-ul{
        left: 0px;
        right: 0px;
        width: 100%;
        min-width: unset;
        max-width: 100%;
        margin-top: 0px;
      }
    }
  }
}