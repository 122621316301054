/*
* Content Loader Styles
*/
$outer-circle: #337ab7;
$inner-circle: #002538;
$loader-size: 18px;

.content-loader {
   width: $loader-size;
   height: $loader-size;
   position: relative;
   vertical-align: middle;
   border-radius: 50%;
   border-width: 3px;
   border-style: solid;
   border-color: $outer-circle;
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;

   .inner {
      width: $loader-size;
      height: $loader-size;
      position: absolute;
      top: -3px;
      left: -3px;
      border-radius: 50%;
      border-width: 3px;
      border-style: solid;
      border-color: transparent;
      border-top-color: $inner-circle;
      animation: loader-moation 400ms linear infinite;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
   }
}

@keyframes loader-moation {
   100% { transform: rotate(360deg); }
}

/*
* Done loading indicator
*/
$done-loading: #1E9F75;

.content-loader-done {
   position: absolute;
   margin-left: 3px;
   animation: loader-out 1000ms linear 1;
   animation-fill-mode: forwards;
   animation-direction: forwards;
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;

   .fa {
      width: auto;
      color: $done-loading;
   }
}

@keyframes loader-out {
   100% { opacity: 0; }
}

/* Helpers */
.form-el-loader {
   display: inline-block;
   width: $loader-size;
   height: $loader-size;
   padding: 8px 8px 0;
   position: relative;
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
}

.content-loader-wrapper {
   text-align: center;
   padding: 15px 0;
}

.content-loader.center {
   display: inline-block;
   margin-left: auto;
   margin-right: auto;
}

.content-loader.loader-sm {
   width: 15px;
   height: 15px;
   border-width: 2px;
   top: 22px;

   .inner {
      width: 15px;
      height: 15px;
      left: -2px;
      top: -2px;
      border-width: 2px;
   }
}

.content-loader.loader-lg {
   width: 50px;
   height: 50px;
   border-width: 5px;

   .inner {
      width: 50px;
      height: 50px;
      left: -5px;
      top: -5px;
      border-width: 5px;
   }
}

.table-loader-wrapper {
   display: block;
   position: relative;
   width: 100%;
   height: 4px;
   @media (max-width: 1200px){
   }
}

.linear-activity {
   position: absolute;
   overflow: hidden;
   width: 100%;
   height: 100%;
   background-color: lighten($outer-circle,20%);
}

.indeterminate {
   position: relative;
   width: 100%;
   height: 100%;
}

.indeterminate:before {
   content: '';
   position: absolute;
   height: 100%;
   background-color: $outer-circle;
   animation: indeterminate_first 1.5s infinite ease-out;
}

.indeterminate:after {
   content: '';
   position: absolute;
   height: 100%;
   background-color: lighten($outer-circle,10%);
   animation: indeterminate_second 1.5s infinite ease-in;
}

@keyframes indeterminate_first {
   0% {
      left: -100%;
      width: 100%;
   }
   100% {
      left: 100%;
      width: 10%;
   }
}

@keyframes indeterminate_second {
   0% {
      left: -150%;
      width: 100%;
   }
   100% {
      left: 100%;
      width: 10%;
   }
}
