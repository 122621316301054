.device-notification {
  display: inline-block;
  margin: 0;
  padding: 2px 6px;
  vertical-align: middle;
  border-radius: 11px;
  font-size: 9px;
  font-weight: 700;
  text-transform: uppercase;

  &.notification-alert {
    background: #dff0d0;
    border: 1px solid #65b500;
    color: #266900;
    right: 0;
  }
  &.notification-warning {
    margin-right: 2px;
    background: #f5dbd9;
    border: 1px solid #ebafa6;
    color: #a32100;
  }
}